export const cityList = {
    tp: {
        city: 'https://tenki.jp/forecast/6/31/6310/28214/10days.html',
    },
    wn: {
        city: 'https://weathernews.jp/onebox/tenki/hyogo/28214/',
    },
    yt: {
        city: 'https://weather.yahoo.co.jp/weather/jp/28/6310/28214.html#week',
    },
};
